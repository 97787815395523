<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">신청인 정보</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>신청인ID</th>
          <td>{{ viewModel.model.apply_id}}</td>
          <th>신청인 이름</th>
          <td>{{ viewModel.model.apply_name}}</td>
        </tr>
        <tr>
          <th>신청인 전화번호</th>
          <td>{{ viewModel.model.apply_phone_number}}</td>
          <th>관계</th>
          <td>{{ viewModel.model.apply_relationship}}</td>
        </tr>
      </template>
    </TableView>
    <Fold
      v-for="(item,index) in viewModel.model.card_apply_list"
      :key="`cardApplyItem${index}`"
      :title="`카드 신청서${ index+1 }`"
      :subTitle="`신청서ID : ${item.card_delivery_uid}`">
      <template v-slot:body_fold>
        <TableView>
          <!-- <template v-slot:tbl_top>
            <div class="area_left">
              <strong class="tit_tbl">카드 신청서{{ index+1 }}</strong>
            </div>
          </template> -->
          <template v-slot:tbl_colgroup>
            <col style="width:190px"/>
            <col/>
            <col style="width:190px"/>
            <col/>
          </template>
          <template v-slot:tbl_body>
            <tr>
              <!-- <th>신청서ID</th>
              <td>{{ item.card_delivery_uid}}</td> -->
              <th>상태</th>
              <td colspan="3">{{ item.status | convertIdToText('card_apply_status') }}</td>
            </tr>
            <tr>
              <th>사용인ID</th>
              <td>{{ item.user_id || '미정'}}</td>
              <th>사용인 이름</th>
              <td>{{ item.user_name || '미정'}}</td>
            </tr>
            <tr>
              <th>신청확정일시</th>
              <td>{{ item.apply_at | convertDateFormat('YYYY-MM-DD HH:mm:ss')}}</td>
              <th>배송완료일시</th>
              <td>{{ item.delivery_at | convertDateFormat('YYYY-MM-DD HH:mm:ss')}}</td>
            </tr>
            <tr>
              <th>주소</th>
              <td colspan="3">
                <span class="txt_tbl">{{ item.address_code}}<br>{{ item.address1 }}<br>{{ item.address2 }}</span>
              </td>
            </tr>
          </template>
        </TableView>
      </template>
    </Fold>
    <!-- <Board
      :boardData="viewModel.userBoardData.CARD_APPLY.boardData"
      :searchParams.sync="viewModel.userBoardData.CARD_APPLY.searchParams"
      :dataList="viewModel.model.card_apply_list"
      :paginationData="viewModel.userBoardData.CARD_APPLY.paginationData"
      @onClickRow="rowId => viewModel.onClickRow(rowId)">
    </Board> -->
    <Board
      :boardData="viewModel.userBoardData.CS.boardData"
      :searchParams.sync="viewModel.userBoardData.CS.searchParams"
      :dataList="viewModel.model.cs_list"
      :paginationData="viewModel.userBoardData.CS.paginationData"
      @onClickRow="rowId => viewModel.onClickRow(rowId)">
    </Board>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록으로"
          routeName="SERVICE_PROMOTION_LIST"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Fold from '@lemontree-ai/lemontree-admin-common-front/components/common/fold/Fold';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import CardApplyDetailViewModel from '@/views/service/card/viewModel/CardApplyDetailViewModel'

export default {
  name: 'CardApplyDetail',
  components:{
    PageWithLayout,
    TableView,
    Fold,
    Board,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new CardApplyDetailViewModel(),
    }
  },
}
</script>

<style scoped>
</style>