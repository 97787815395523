import CardApplyModel from '@/views/service/card/model/CardApplyModel';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class CardApplyDetailViewModel {
  constructor() {
    this.model = new CardApplyModel();
    this.userBoardData = {
      CARD_APPLY:{
        boardData : {
          title:'카드 신청 내역',
          drawDataList: [
            {
              title:'신청서 ID',
              width:'100px',
              value:'card_delivery_uid',
              isAlignLeft: true
            },
            {
              title:'사용인ID',
              width:'80px',
              value:'user_id',
              emptyValueText: '미정',
              isAlignLeft: true
            },
            {
              title:'사용인 이름',
              width:'80px',
              value:'user_name',
              emptyValueText: '미정',
              isAlignLeft: true
            },
            {
              title:'발급구분',
              width:'80px',
              value:'apply_type',
              filter:{
                name:'convertIdToText',
                value:'card_type',
                value3:'미확인'
              },
              isAlignLeft: true
            },
            {
              title:'수수료',
              width:'60px',
              value:'apply_fee',
              isAlignLeft: true
            },
            {
              title:'신청확정일시',
              width:'',
              value:'apply_at',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD HH:mm:ss'
              },
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'배송완료일시',
              width:'',
              value:'delivery_at',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD HH:mm:ss'
              },
              isAlignLeft: true,
              isEllip: true
            },
            {
              title:'처리상태',
              width:'',
              value:'status',
              filter:{
                name:'convertIdToText',
                value:'card_apply_status'
              },
              // statusSelect:{
              //   dataList:'card_apply_status',
              // },
              isAlignLeft: true
            },
          ],
          option:{
            isTotal: true,
            rowIdValue: 'card_delivery_uid'
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:1,
          totalPage:1
        }
      },
      CS:{
        boardData : {
          title:'CS 응대 내역',
          drawDataList: [
            {
              title:'인입일',
              width:'104px',
              value:'cearted_at',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD'
              }
            },
            {
              title:'문의내용',
              value:'title',
              isEllip: true,
              isAlignLeft: true
            },
            {
              title:'CS 담당자',
              width:'80px',
              value:'cs_manager_name'
            },
            {
              title:'처리상태',
              width:'190px',
              value:'status',
              statusSelect:{
                dataList:'common_cs_status',
              }
            },
            {
              title:'완료일',
              width:'104px',
              value:'complete_at',
              emptyValueText:'-',
              filter:{
                name:'convertDateFormat',
                value:'YYYY-MM-DD'
              },
            },
          ],
          option:{
            isTotal: true,
          }
        },
        searchParams: {
          page:0,
          pageSize:10,
          direction: 'ASC'
        },
        paginationData: {
          totalCount:1,
          totalPage:1
        }
      }
    }
  }

  init(id){
    if(id){
      this.getCardApplyDetail(id)
    }
  }
  onUpdateCodeName(){
    this.model.resetCodeNameDiffCheck()
  }
  onClickDiffCheck(){
    const result = {
      is_code_name_diff: Boolean(Math.round(Math.random())),
      code_name: this.model.code_name
    }
    this.model.setCodeNameDiffCheck(result)
  }
  
  onClickComplete(){
    let toastMessage = '';
    let routeName = '';
    if(this.isModify){
      toastMessage = '수정';
      routeName = 'APP_TERMS_DETAIL';
    }else{
      toastMessage = '등록';
      routeName = 'APP_TERMS_LIST';
    }
    store.dispatch('commonToast/fetchToastStart', `FAQ ${toastMessage}이 완료되었습니다.`);
    router.push({ name: routeName });
  }

  getFaqCategory(){
    this.categoryDataList = [
      { id:1, categoryName:'레몬트리카드', editabled: true },
      { id:2, categoryName:'인증/보안', editabled: true },
      { id:0, categoryName:'기타', editabled: false },
    ]
  }

  getCardApplyDetail(id){
    const resultData = {
      apply_id:'ISDNF8G',
      apply_name: '윤엄마',
      apply_phone_number: '010-1234-5678',
      apply_relationship: '그룹',
      card_apply_list:[
        {
          card_delivery_uid:'UEHGBDJ',
          apply_at: '2022-06-27T12:05:01',
          user_id:'PSOND7T',
          user_name:'정*리',
          delivery_at:'',
          apply_type:'NEW',
          apply_fee:'무료',
          status:'REGISTERED',
          address_code:'123558',
          address1:'경기도 성남시 분당구 성남대로331번길 8',
          address2:'507호',
        },
        {
          card_delivery_uid:'OPSTNGW',
          apply_at: '2022-06-27T12:05:01',
          user_id:'',
          user_name:'',
          delivery_at:'',
          apply_type:'',
          apply_fee:'무료',
          status:'PENDING',
          address_code:'123558',
          address1:'경기도 성남시 분당구 성남대로331번길 8',
          address2:'507호',
        },
        {
          card_delivery_uid:'HPSTNGD',
          apply_at: '2022-06-27T12:05:01',
          user_id:'ASFHTB',
          user_name:'정*몬',
          delivery_at:'2022-06-19T12:05:01',
          apply_type:'REAPPLY',
          apply_fee:'무료',
          status:'ARRIVE',
          address_code:'123558',
          address1:'경기도 성남시 분당구 성남대로331번길 8',
          address2:'507호',
        },
      ],
      cs_list: [
        {
          id:1,
          cearted_at:'2022-04-11T12:05:01',
          title:'자녀 카드를 신청했는데 오지 않습니다',
          cs_manager_name:'박하영',
          status:'COMPLETE',
          complete_at:'2022-04-11T15:05:01',
        }
      ],
    };
    this.model.setData(resultData);
  }
}