import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'

export default class CardApplyModel {
  constructor(){
    this.objRaw = {};
    this.apply_id = '';
    this.apply_name = '';
    this.apply_phone_number = '';
    this.apply_relationship = '';
    this.apply_at = '';
    this.card_apply_list = '';
    this.cs_list = '';
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { apply_id, apply_name, apply_phone_number, apply_relationship, apply_at, card_apply_list, cs_list } = obj;
    this.apply_id = apply_id;
    this.apply_name = apply_name;
    this.apply_phone_number = apply_phone_number;
    this.apply_relationship = apply_relationship;
    this.apply_at = apply_at;
    this.card_apply_list = card_apply_list;
    this.cs_list = cs_list;
  }
}